require(['jquery'], function ($) {

    // Hide Qty box if no price
    if ($('.xm-grid-product').length && $('.xm-grid-product .price[content="0"]').length) {
        $('.xm-grid-product .qty-wrapper').hide();
    };
    
    //Add fa icons to CTA buttons
    $('.product-shop .add-cart-button span span').prepend('<i class="fa fa-plus-circle" aria-hidden="true"></i>');
    $('.continue-shopping-button span span').prepend('<i class="fa fa-chevron-circle-left"></i>');
    $('.view-cart-button span span').prepend('<i class="fa fa-chevron-circle-right"></i>');
    $('#product-tabs ul li a').prepend('<i class="fa fa-chevron-circle-down"></i>');
    
    //show or hide tab information if the panels are clicked
    $('#product-tabs li').click(function() {
      var hidden= ("none" == $(this).parent().siblings('.tabs-panels').css('display'));
      $(this).find('.fa').remove();
      if(hidden) {
        $(this).parent().siblings('.tabs-panels').show();
        $('a', this).prepend('<i class="fa fa-chevron-circle-up"></i>');
      }
      else {
        $(this).parent().siblings('.tabs-panels').hide();
        $('a', this).prepend('<i class="fa fa-chevron-circle-down"></i>');
      }
    });
  
});